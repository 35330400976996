.brand {
    opacity: 0;
    width: 0;
    max-width: fit-content;
    transition: all 400ms cubic-bezier(0.215, 0.61, 0.355, 1);

    svg {
        min-width: 36px;
    }
}

.show {
    opacity: 1;
    width: 20%;
}
