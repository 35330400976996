/*
    Overriding bootstrap CSS to match spec (opacity) and to prevent the control
    overlay from covering the buttons on the cards (width).
*/
.carousel-control-next,
.carousel-control-prev {
    width: 0 !important;
    opacity: 1 !important;
}

/* Adding padding and negative margin to allow drop shadow to show correctly.*/
.carousel-inner {
    padding: 1rem;
    margin: -1rem;
    width: auto !important;
}
