$time-scale: 2;

.fixed {
    position: fixed !important;
    margin: 0 auto !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.skCircle {
    display: block;
    margin: 64px auto;
    position: relative;
    width: 200px;
    height: 200px;
}

.small {
    width: 50px;
    height: 50px;
}
.large {
    width: 200px;
    height: 200px;
}

.skCircle .skChild {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.skCircle .skChild:before {
    content: "";
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #0a8aee;
    border-radius: 100%;
    -webkit-animation: sk-circleBounceDelay (1.2s * $time-scale) infinite
        ease-in-out both;
    animation: sk-circleBounceDelay (1.2s * $time-scale) infinite ease-in-out
        both;
}
.skCircle .skCircle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
}
.skCircle .skCircle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
}
.skCircle .skCircle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
}
.skCircle .skCircle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
}
.skCircle .skCircle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
}
.skCircle .skCircle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
.skCircle .skCircle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
}
.skCircle .skCircle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
}
.skCircle .skCircle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
}
.skCircle .skCircle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
}
.skCircle .skCircle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
}
.skCircle .skCircle2:before {
    -webkit-animation-delay: (-1.1s * $time-scale);
    animation-delay: (-1.1s * $time-scale);
}
.skCircle .skCircle3:before {
    -webkit-animation-delay: (-1s * $time-scale);
    animation-delay: (-1s * $time-scale);
}
.skCircle .skCircle4:before {
    -webkit-animation-delay: (-0.9s * $time-scale);
    animation-delay: (-0.9s * $time-scale);
}
.skCircle .skCircle5:before {
    -webkit-animation-delay: (-0.8s * $time-scale);
    animation-delay: (-0.8s * $time-scale);
}
.skCircle .skCircle6:before {
    -webkit-animation-delay: (-0.7s * $time-scale);
    animation-delay: (-0.7s * $time-scale);
}
.skCircle .skCircle7:before {
    -webkit-animation-delay: (-0.6s * $time-scale);
    animation-delay: (-0.6s * $time-scale);
}
.skCircle .skCircle8:before {
    -webkit-animation-delay: (-0.5s * $time-scale);
    animation-delay: (-0.5s * $time-scale);
}
.skCircle .skCircle9:before {
    -webkit-animation-delay: (-0.4s * $time-scale);
    animation-delay: (-0.4s * $time-scale);
}
.skCircle .skCircle10:before {
    -webkit-animation-delay: (-0.3s * $time-scale);
    animation-delay: (-0.3s * $time-scale);
}
.skCircle .skCircle11:before {
    -webkit-animation-delay: (-0.2s * $time-scale);
    animation-delay: (-0.2s * $time-scale);
}
.skCircle .skCircle12:before {
    -webkit-animation-delay: (-0.1s * $time-scale);
    animation-delay: (-0.1s * $time-scale);
}

@-webkit-keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

@keyframes sk-circleBounceDelay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
