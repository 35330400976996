.mobileNav {
    transform: translateX(-100%);
    transition: transform 300ms ease-in-out;
    background-color: white;
    position: fixed;
    top: 64px;
    left: 0;
    height: 100vh;
    z-index: 999;
    min-width: 275px;
    height: 100vh;

    &.open {
        transform: translateX(0);
    }
}
