@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.row-min-height-responsive {
    min-height: 0;

    @include media-breakpoint-up(md) {
        min-height: calc(100vh - 64px);
    }
}
